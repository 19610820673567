<template>
  <div>
    <h1 class="title has-text-centered">
      Pokémon GO Beyond 40
    </h1>

    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child box">
          <div class="mx-2">
            <p class="title">{{ tasks[0].title }}</p>
            <ul>
              <li>{{ tasks[0].one }}</li>
              <li>{{ tasks[0].two }}</li>
              <li>{{ tasks[0].three }}</li>
              <li>{{ tasks[0].four }}</li>
              <li>{{ tasks[0].five }}</li>
            </ul>
          </div>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box">
          <div class="mx-2">
            <p class="title">{{ tasks[1].title }}</p>
            <ul>
              <li>{{ tasks[1].one }}</li>
              <li>{{ tasks[1].two }}</li>
              <li>{{ tasks[1].three }}</li>
              <li>{{ tasks[1].four }}</li>
              <li>{{ tasks[1].five }}</li>
            </ul>
          </div>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box">
          <div class="mx-2">
            <p class="title">{{ tasks[2].title }}</p>
            <ul>
              <li>{{ tasks[2].one }}</li>
              <li>{{ tasks[2].two }}</li>
              <li>{{ tasks[2].three }}</li>
              <li>{{ tasks[2].four }}</li>
              <li>{{ tasks[2].five }}</li>
            </ul>
          </div>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box">
          <div class="mx-2">
            <p class="title">{{ tasks[3].title }}</p>
            <ul>
              <li>{{ tasks[3].one }}</li>
              <li>{{ tasks[3].two }}</li>
              <li>{{ tasks[3].three }}</li>
              <li>{{ tasks[3].four }}</li>
              <li>{{ tasks[3].five }}</li>
            </ul>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child box">
          <div class="mx-2">
            <p class="title">{{ tasks[4].title }}</p>
            <ul>
              <li>{{ tasks[4].one }}</li>
              <li>{{ tasks[4].two }}</li>
              <li>{{ tasks[4].three }}</li>
              <li>{{ tasks[4].four }}</li>
              <li>{{ tasks[4].five }}</li>
            </ul>
          </div>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box">
          <div class="mx-2">
            <p class="title">{{ tasks[5].title }}</p>
            <ul>
              <li>{{ tasks[5].one }}</li>
              <li>{{ tasks[5].two }}</li>
              <li>{{ tasks[5].three }}</li>
              <li>{{ tasks[5].four }}</li>
              <li>{{ tasks[5].five }}</li>
            </ul>
          </div>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box">
          <div class="mx-2">
            <p class="title">{{ tasks[6].title }}</p>
            <ul>
              <li>{{ tasks[6].one }}</li>
              <li>{{ tasks[6].two }}</li>
              <li>{{ tasks[6].three }}</li>
              <li>{{ tasks[6].four }}</li>
              <li>{{ tasks[6].five }}</li>
            </ul>
          </div>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box">
          <div class="mx-2">
            <p class="title">{{ tasks[7].title }}</p>
            <ul>
              <li>{{ tasks[7].one }}</li>
              <li>{{ tasks[7].two }}</li>
              <li>{{ tasks[7].three }}</li>
              <li>{{ tasks[7].four }}</li>
              <li>{{ tasks[7].five }}</li>
            </ul>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child box">
          <div class="mx-2">
            <p class="title">{{ tasks[8].title }}</p>
            <ul>
              <li>{{ tasks[8].one }}</li>
              <li>{{ tasks[8].two }}</li>
              <li>{{ tasks[8].three }}</li>
              <li>{{ tasks[8].four }}</li>
              <li>{{ tasks[8].five }}</li>
            </ul>
          </div>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box">
          <div class="mx-2">
            <p class="title">{{ tasks[9].title }}</p>
            <ul>
              <li>{{ tasks[9].one }}</li>
              <li>{{ tasks[9].two }}</li>
              <li>{{ tasks[9].three }}</li>
              <li>{{ tasks[9].four }}</li>
              <li>{{ tasks[9].five }}</li>
            </ul>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { tasks } from './beyond';

export default {
  name: 'PogoBeyond',
  props: {},
  data() {
    return {
      tasks,
    };
  },
};
</script>

<style lang="css" scoped>
ul {
  list-style: disc outside;
}
</style>
