export let tasks = [
  {
    id: 1,
    title: 'Level 41',
    one: 'Power up a Legendary Pokémon 20 times',
    two: 'Win 30 raids',
    three: 'Catch 200 Pokémon in a single day',
    four: 'Earn 5 Gold Medals',
    five: 'Have 26 000 000 XP',
  },
  {
    id: 2,
    title: 'Level 42',
    one: 'Evolve Eevee into each of its unique Evolutions',
    two: 'Use items to evolve Pokémon 15 times',
    three: 'Make 3 Excellent Throws',
    four: 'Use 200 Berries to help catch Pokémon',
    five: 'Have 33 500 000 XP',
  },
  {
    id: 3,
    title: 'Level 43',
    one: 'Earn 100 000 Stardust',
    two: 'Use 200 Supereffective Charged Attacks',
    three: 'Catch 5 Legendary Pokémon',
    four: 'Earn 5 Platinum Medals',
    five: 'Have 42 500 000 XP',
  },
  {
    id: 4,
    title: 'Level 44',
    one: 'Win 30 Trainer Battles in the Great Leauge',
    two: 'Win 30 Trainer Battles in the Ultra Leauge',
    three: 'Win 30 Trainer Battles in the Master Leauge',
    four: 'Win 20 Trainer Battles in the GO Battle Leauge',
    five: 'Have 53 500 000 XP',
  },
  {
    id: 5,
    title: 'Level 45',
    one: 'Defeat 100 Team Go Rocket Grunts',
    two: 'Purify 100 Shadow Pokémon',
    three: 'Defeat a Team Go Rocket Leader 50 times',
    four: 'Earn 10 Platinum Medals',
    five: 'Have 66 500 000 XP',
  },
  {
    id: 6,
    title: 'Level 46',
    one: 'Complete 100 Field Research tasks',
    two: 'Take a snapshot of a Pokémon 7 days in a row',
    three: 'Make 50 Excellent Throws',
    four: 'Hatch 30 Eggs',
    five: 'Have 82 000 000 XP',
  },
  {
    id: 7,
    title: 'Level 47',
    one: 'Win 30 raids using a team of all unique Pokemon species',
    two: 'Win a three-star raid using only Pokemon with 1,500 CP or less',
    three: 'Power up 3 Pokemon to their max CP',
    four: 'Earn 20 Platinum Medals',
    five: 'Have 100 000 000 XP',
  },
  {
    id: 8,
    title: 'Level 48',
    one: 'Receive 10 Souvenirs from your buddy',
    two: 'Earn 300 hearts with your buddy',
    three: 'Walk 200km with your buddy',
    four: 'Walk 25km in a week 8 times',
    five: 'Have 121 000 000 XP',
  },
  {
    id: 9,
    title: 'Level 49',
    one: 'Make 10 trades with Pokémon caught at least 300 km apart',
    two: 'Obtain 50 Lucky Pokémon in trades',
    three: 'Send 500 Gifts to friends',
    four: 'Earn 35 Platinum Medals',
    five: 'Have 146 600 000 XP',
  },
  {
    id: 10,
    title: 'Level 50',
    one: 'Make 999 Excellent Throws',
    two:
      'Catch a Legendary Pokémon in your next 5 Legendary Pokémon encounters',
    three:
      'Defeat a Team GO Rocket Leader 3 times using only Pokémon with 2,500 CP or less',
    four: 'Achieve Rank 10 in the GO Battle League',
    five: 'Have 176 000 000 XP',
  },
];
