<template>
  <b-navbar type="is-primary">
    <template v-slot:brand>
      <b-navbar-item tag="router-link" to="/">
        Slask.dev
      </b-navbar-item>
    </template>
    <template v-slot:start>
      <b-navbar-item tag="router-link" to="/">
        Home
      </b-navbar-item>
      <b-navbar-item tag="router-link" to="/pogo/beyond">
        Pokémon GO Beyond 40
      </b-navbar-item>
      <!-- <b-navbar-dropdown label="Pokémon GO" hoverable collapsible>
        <b-navbar-item tag="router-link" to="/pogo/beyond">
          Beyond 40
        </b-navbar-item>
      </b-navbar-dropdown> -->
    </template>

    <template v-slot:end> </template>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navbar',
  components: {},
  props: {},
};
</script>

<style></style>
