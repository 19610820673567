import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Pogo from '../views/pogo/Index.vue';
import PogoBeyond from '../views/pogo/Beyond.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/pogo',
    name: 'Pogo',
    component: Pogo,
    children: [
      {
        path: 'beyond',
        name: 'Beyond',
        component: PogoBeyond,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
